import { FunctionComponent, useMemo, useState } from "react";
import { INavLinkGroup, MoreLessToggle, Nav, Stack } from "@bps/fluent-ui";

export type SectionConfig = { id: string; name: string };

export type ExpandableNavItem = {
  name: string;
  hasPermission?: boolean;
  links: SectionConfig[];
};

interface ExpandableNavProps {
  selectedKey: string | undefined;
  onSectionChange: (key: string) => void;
  items: ExpandableNavItem[];
}

export const ExpandableNav: FunctionComponent<ExpandableNavProps> = ({
  selectedKey,
  onSectionChange,
  items
}) => {
  const expendedKeyName = items.find(i =>
    i.links.some(l => l.id === selectedKey)
  )?.name;

  const initialExpendedValue = useMemo(
    () =>
      expendedKeyName ? new Set<string>([expendedKeyName]) : new Set<string>(),
    [expendedKeyName]
  );

  const [expended, setExpended] = useState<Set<string>>(initialExpendedValue);

  const groups: INavLinkGroup[] = useMemo(() => {
    return items
      .filter(i => {
        if (typeof i.hasPermission === "undefined") return true;
        return i.hasPermission;
      })
      .map(i => ({
        name: i.name,
        links: i.links.map(l => ({ key: l.id, name: l.name, url: "" })),
        isExpanded: expended.has(i.name),
        onHeaderClick: () => {
          if (i.name) {
            setExpended(prev =>
              prev.has(i.name)
                ? new Set([...prev].filter(x => x !== i.name))
                : new Set(prev.add(i.name))
            );
          }
        }
      }));
  }, [expended, items]);

  return (
    <>
      <MoreLessToggle
        value={expended.size === items.length}
        onChanged={value => {
          if (value) {
            setExpended(new Set(items.map(i => i.name)));
          } else {
            setExpended(new Set(initialExpendedValue));
          }
        }}
        styles={{ root: { alignSelf: "end" } }}
        linkProps={{
          moreStateText: "Collapse all",
          lessStateText: "Expand all"
        }}
      />
      <Stack
        verticalFill
        styles={{ root: { flex: "1 1 0", overflowY: "auto" } }}
      >
        <Nav
          groups={groups}
          selectedKey={selectedKey}
          onLinkClick={(_evt, item) => item?.key && onSectionChange(item.key)}
          styles={{ groupContent: { margin: 0 } }}
        />
      </Stack>
    </>
  );
};
