import React, { FunctionComponent, useState } from "react";
import { FormTextField } from "./FormTextField";
import { FontIcon, TooltipHost, useTheme } from "@bps/fluent-ui";
import { PIN_LENGTH } from "@components/form/fields/FormPin.validator";

const PIN_TOOLTIP = "Show pin";
const PIN_PLACEHOLDER = "6 characters pin code";
const PIN_LABEL = "PIN code";

export interface FormPinFieldProps {
  name: string;
  description?: string;
}

export const FormPinField: FunctionComponent<FormPinFieldProps> = ({
  name,
  description
}) => {
  const theme = useTheme();
  const [showPin, setShowPin] = useState<boolean>(false);

  return (
    <FormTextField
      type={showPin ? "text" : "password"}
      label={PIN_LABEL}
      placeholder={PIN_PLACEHOLDER}
      name={name}
      max={PIN_LENGTH}
      min={PIN_LENGTH}
      styles={{ suffix: { padding: 0 }, description: { fontSize: 12 } }}
      description={description}
      onRenderSuffix={() => (
        <TooltipHost content={PIN_TOOLTIP}>
          <FontIcon
            iconName="RedEye"
            onClick={() => setShowPin(prev => !prev)}
            styles={{
              root: {
                cursor: "pointer",
                padding: "10px 8px",
                color: showPin
                  ? theme.palette.themePrimary
                  : theme.palette.neutralSecondaryAlt
              }
            }}
          />
        </TooltipHost>
      )}
    />
  );
};
