import { ValidationSchema, Validator as ValidatorBase } from "@bps/utils";
import { ValidationFieldFormat } from "@components/form/types/validate.types";

//extends the Validator to parse error messages to react-hook-forms format
export class Validator<
  ParentValues extends {},
  FormValues extends {} = ParentValues
> extends ValidatorBase<ParentValues, FormValues> {
  public parseErrorMessages = (errorMessage: string): ValidationFieldFormat => {
    return {
      type: "validation",
      message: errorMessage
    };
  };

  public validateWithParse = (
    values: ParentValues,
    validationSchema: ValidationSchema<ParentValues, FormValues> = this.schema
  ) => {
    return this.validate(values, validationSchema, {
      errorMessageParser: this.parseErrorMessages
    });
  };
}
