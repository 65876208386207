import { FunctionComponent } from "react";
import { Option, OptionsSelect, OptionsSelectProps } from "@bps/fluent-ui";
import { useComponentsDefs } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { ComponentDefDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface ComponentDefinitionSelectProps
  extends Omit<OptionsSelectProps, "options" | "multiSelect"> {}

export const ComponentDefinitionSelect: FunctionComponent<ComponentDefinitionSelectProps> = ({
  ...props
}) => {
  const { isLoading, error, data = [] } = useComponentsDefs();

  const options: Option<ComponentDefDto>[] = data
    .filter(code => code.isActive)
    .map(data => ({
      key: data?.code,
      text: data.code,
      data
    }));

  return (
    <OptionsSelect
      options={options}
      errorMessage={error?.message}
      loading={isLoading}
      placeholder="Select component code"
      multiSelect
      {...props}
    />
  );
};
