import { SalesProductDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent } from "react";

import {
  LabeledText,
  LabeledTextGroup,
  Option,
  OptionsSelect,
  OptionsSelectProps,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { useSalesProducts } from "@libs/api/gateways/plt/plt-gateway.hooks";

export interface ProductsSelectProps
  extends Omit<OptionsSelectProps, "options"> {
  onFilterProducts?: (item: SalesProductDto) => boolean;
}

export const ProductsSelect: FunctionComponent<ProductsSelectProps> = ({
  onFilterProducts,
  ...rest
}) => {
  const theme = useTheme();
  const { isLoading, error, data = [] } = useSalesProducts();
  const products = onFilterProducts ? data.filter(onFilterProducts) : data;

  const options: Option<SalesProductDto>[] = products
    .filter(p => !p.isInactive)
    .map(data => ({
      key: data?.id,
      text: `${data.id}/${data.name}/${data.description}/${data.displayName}/${data.source}/${data.productCode}/${data.applicationCode}/${data.sourceProductId}`,
      data
    }));

  return (
    <OptionsSelect
      options={options}
      errorMessage={error?.message}
      loading={isLoading}
      styles={{
        cell: { borderBottom: `1px solid ${theme.palette.neutralTertiary}` },
        singleOption: { padding: 8 }
      }}
      onRenderFieldContent={selectedOptions =>
        selectedOptions.map(o => o.data?.displayName).join(", ")
      }
      onRenderOption={(option: Option<SalesProductDto>) => (
        <TooltipHost
          styles={{ root: { width: "100%", display: "block" } }}
          content={
            <LabeledTextGroup
              styles={{
                root: {
                  alignItems: "start",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 4
                }
              }}
            >
              <LabeledText label="Display name">
                {option.data?.displayName ?? "-"}
              </LabeledText>
              <LabeledText label="Product Family" wrap>
                <Text variant="small">{option.data?.productFamily ?? "-"}</Text>
              </LabeledText>
              <LabeledText label="Product Code">
                <Text variant="small">{option.data?.productCode ?? "-"}</Text>
              </LabeledText>
              <LabeledText label="Licence type" wrap>
                <Text variant="small">{option.data?.licenceType ?? "-"}</Text>
              </LabeledText>
            </LabeledTextGroup>
          }
        >
          <Text bold styles={{ root: { width: "100%" } }}>
            {option.data?.name}
          </Text>
        </TooltipHost>
      )}
      {...rest}
    />
  );
};
