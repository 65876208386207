import { FunctionComponent } from "react";
import { FormFieldProps } from "./FormFieldProps";
import { useController } from "react-hook-form";
import {
  ComponentDefinitionSelect,
  ComponentDefinitionSelectProps
} from "../../pickers/ComponentDefinitionSelect";

type FormComponentDefinitionSelectProps = Omit<
  ComponentDefinitionSelectProps,
  "selectedKeys" | "onChangeSelectedKeys"
> &
  FormFieldProps;

export const FormComponentDefinitionSelect: FunctionComponent<FormComponentDefinitionSelectProps> = ({
  name,
  rules,
  ...rest
}) => {
  const {
    field: { value, onChange, ref, ...fieldProps }
  } = useController({ name, rules });

  return (
    <ComponentDefinitionSelect
      selectedKeys={value}
      onChangeSelectedKeys={onChange}
      {...rest}
      {...fieldProps}
    />
  );
};
