import { Validator } from "@components/form/validation/Validator";

export const PIN_LENGTH = 6;

const validator = new Validator();
export const pinLengthSchema = validator
  .string()
  .requiredLength(6, {
    message: "Pin code length should be 6 characters."
  })
  .required();
